<template>
  <div class="font-stolzl font-semibold">
    <div class="w-screen h-screen flex flex-row justify-center items-center">
    {{text.notFound}}
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/footer.vue';

export default {
  name: 'NotFound',
  components: { Footer },
   computed:{
    text(){
      return this.$store.state.lang.notFound[this.$store.state.lang.currentLang];
    },
   }
};
</script>